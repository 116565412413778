<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">舆图信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="附件类型" prop="fjlx">
                    <el-select
                        v-model="formdata.saveJson.fjlx"
                        filterable
                        clearable
                        placeholder="请选择附件类型"
                        :disabled="!isEdit || formdata.metaJson.length > 0"
                        @change="changeType"
                    >
                        <el-option label="文档类" value="2802"></el-option>
                        <el-option label="图片类" value="2801"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="资料名称" prop="zlmc">
                    <el-input
                        v-model="formdata.saveJson.zlmc"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <basic-upload
                    v-if="
                        formdata.saveJson.sjmj === '公开' &&
                            formdata.saveJson.fjlx
                    "
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    :basicType="
                        formdata.saveJson.fjlx === '2801'
                            ? 'picture'
                            : 'document'
                    "
                ></basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "assembly_resolutions",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                fjlx: [
                    {
                        required: true,
                        message: "请选择附件类型",
                        trigger: "blur",
                    },
                ],
                zlmc: [
                    {
                        required: true,
                        message: "请填写资料名称",
                        trigger: "blur",
                    },
                ],
            },
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    fjlx: "2802",
                    zlmc: "",
                    shzt: 1,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "2802",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        changeType(value) {
            Object.keys(this.formdata.saveJson).forEach((key) => {
                if (
                    ["sjmj", "fbfw", "fjlx", "shzt", "id"].indexOf(key) === -1
                ) {
                    delete this.formdata.saveJson[key];
                }
            });
            this.formdata.itemID = value;
        },
    },
};
</script>
<style lang="scss" scoped></style>
